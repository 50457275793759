import React, { useEffect, useState } from 'react';
import { CardArticle, AdContainer } from '@forbes/fbs-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import FourCardArticle from '../FourCardArticle/FourCardArticle';
import ThreeCardStream from '../ThreeCardStream/ThreeCardStream';
import { fireGAEvent } from '../../shared/trackingUtils';
import { fetchArticles } from '../PopularArticles/utils';
import throttle from '../../shared/throttle';

const PROGRESSIVE_LOAD_PERCENTAGE = 1;

/**
 * Fires a google analytics event
 * @param {String} label event label
 */
const triggerGAEvent = (label, value) => {
	fireGAEvent({
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: label,
		eventValue: value,
	});
};

/**
 * Creates inline ad component
 * @param {Boolean} isMobile Checks if the device is mobile
 * @param {String} className Additional class name
 * @param {Number} index number of mobilex index
 * @param {Object} customTargeting custom targeting for ad
 * @param addProgressiveAd function to add fbs-ad to shared progressive ads array
 * @returns Inline ad component
 */
const InlineAdX = ({
	isMobile,
	className,
	index,
	customTargeting,
	addProgressiveAd,
}) => (
	<AdContainer
		type={isMobile ? 'inlineAdMobile' : 'inlineAd'}
		className={cx('inline-ad-container', 'xl-recirc-inline-ad', className)}
		showLabel
	>
		{isMobile ? (
			<fbs-ad
				progressive="true"
				position="mob-stream"
				id={`article-0-ad-${index}`}
				ad-id={`inline-article-0-ad-${index}`}
				class="article-mobile-ad"
				ref={addProgressiveAd}
				custom-targeting={`${customTargeting},mffxlrecirc:true`}
			/>
		) : (
			<fbs-ad
				progressive="true"
				position="topx"
				custom-targeting="topx:stream1,mffxlrecirc:true"
				class="topx-ad"
				ref={addProgressiveAd}
			/>
		)}
	</AdContainer>
);

InlineAdX.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	className: PropTypes.string,
	index: PropTypes.number,
	customTargeting: PropTypes.string.isRequired,
	addProgressiveAd: PropTypes.func.isRequired,
};

const XLRecircStream = ({
	data, showTopx, showRecX, className, isVetted = false, isSmallDevice, mobileInlineAds, addProgressiveAd, isDouble,
}) => (
	<>
		{!isVetted && showTopx && !isDouble && <InlineAdX isMobile={isSmallDevice} index={mobileInlineAds + 1} customTargeting="mobilex:stream1" addProgressiveAd={addProgressiveAd} />}
		<div className={cx('xl-recirc-container', className, { 'vetted-recirc-container': isVetted, 'xl-recirc-container-concise': isDouble })}>
			<div className="xl-recirc">
				{!isDouble && <h1 className="section-title">More From Forbes</h1>}
				<FourCardArticle data={data.slice(0, 4)} isSmallDevice={isSmallDevice} isDouble={isDouble} />
				{!isVetted && isSmallDevice && <InlineAdX isMobile index={mobileInlineAds + (isDouble ? 4 : 2)} customTargeting={`mobilex:stream${isDouble ? 4 : 2}`} addProgressiveAd={addProgressiveAd} />}
				<div className="two-sections">
					{data.slice(4, 6).map(({
						index, image, uri, alt, size, title, isPremium, authors, published_date: timestamp,
					}) => (
						<CardArticle
							key={`${title}-${index}`}
							image={image}
							href={uri}
							alt={alt}
							size={size}
							title={title}
							isPremium={isPremium}
							className="two-card-article"
							timestamp={isSmallDevice ? new Date(timestamp).getTime() : null}
							classNameByLines="custom-stream"
							onClick={() => triggerGAEvent(`More From Forbes - XL Recirc - Story ${index + 1}`, index + 1)}
							isLazyLoading
							authors={[
								{
									link: authors[0]?.link,
									name: authors[0]?.name,
									type: authors[0]?.type,
								},
							]}
						/>
					))}
				</div>
				{((isDouble && !isSmallDevice) || !isDouble) ? <FourCardArticle isReversed data={data.slice(6, 10)} hideImage />
					: <ThreeCardStream data={data.slice(6, 9)} isSmallDevice={isSmallDevice} className="three-card-stream-concise" />}
				{!isVetted && isSmallDevice && <InlineAdX isMobile index={mobileInlineAds + (isDouble ? 5 : 3)} customTargeting={`mobilex:stream${isDouble ? 5 : 3}`} addProgressiveAd={addProgressiveAd} />}
				{(!isDouble || isSmallDevice) && <ThreeCardStream data={data.slice(10, 13)} isSmallDevice={isSmallDevice} />}
			</div>
			{!isVetted && showRecX && !isSmallDevice
				&& (
					<div className={cx('recirc-right-rail', { 'right-rail-2': isDouble })}>
						<AdContainer type="rightRail" showLabel>
							<fbs-ad position="stream" custom-targeting={`recx:stream${isDouble ? 2 : 1},mffxlrecirc:true`} ref={addProgressiveAd} progressive="true" />
						</AdContainer>
					</div>
				)}
		</div>
	</>
);

XLRecircStream.propTypes = {
	data: PropTypes.array.isRequired,
	showTopx: PropTypes.bool.isRequired,
	showRecX: PropTypes.bool.isRequired,
	className: PropTypes.string.isRequired,
	isVetted: PropTypes.bool.isRequired,
	isSmallDevice: PropTypes.bool.isRequired,
	mobileInlineAds: PropTypes.number.isRequired,
	addProgressiveAd: PropTypes.func.isRequired,
	isDouble: PropTypes.bool,
};

const XLRecirc = ({
	showTopx = true, showRecX = true, className, isVetted = false, vettedRecircData = [],
}) => {
	const [data, setData] = useState(null);
	const [showXLRecirc, setShowXLRecirc] = useState(false);
	const [isSmallDevice, setIsSmallDevice] = useState(false);
	const [mobileInlineAds, setMobileInlineAds] = useState(undefined);
	const [progressiveAds, setProgressiveAds] = useState([]);

	const addProgressiveAd = (ref) => {
		if (ref && !progressiveAds.includes(ref)) {
			setProgressiveAds((curAds) => [...curAds, ref]);
		}
	};

	useEffect(() => {
		const checkProgressiveAds = () => {
			progressiveAds.forEach((ad) => {
				const { bottom } = ad.getBoundingClientRect();

				// destroy ads when user scrolls past them into next article
				if (bottom < -window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE) {
					if (ad.div && (!ad.hasAttribute('destroyed') || ad.getAttribute('destroyed') === 'false')) {
						ad.destroyAd();
						ad.setAttribute('destroyed', 'true');
					}
					return;
				}

				if ((bottom - window.innerHeight < window.innerHeight * PROGRESSIVE_LOAD_PERCENTAGE)
					&& !ad.hasAttribute('display-called')
				) {
					if (ad.hasAttribute('destroyed') && ad.getAttribute('destroyed') === 'true') {
						ad.removeAttribute('destroyed');
						ad.recallAd();
					} else {
						ad.display();
					}
				}
			});
		};
		checkProgressiveAds();
		const scrollHandler = throttle(checkProgressiveAds, 200);
		window.addEventListener('scroll', scrollHandler, { passive: true });
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, [progressiveAds]);

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetchArticles(true);
				setData(response);
			} catch (e) {
				console.log('Error fetching data 2', e);
			}
		};

		if (isVetted) {
			setData(vettedRecircData);
		} else {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallDevice(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize, { passive: true });

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const mobileXArray = document.querySelectorAll('.article-mobile-ad');
		setMobileInlineAds(mobileXArray.length);
	}, []);

	useEffect(() => {
		const blockComponent = window.forbes['simple-site'].specialSlot || window.forbes['simple-site'].swimLane;
		setShowXLRecirc(!blockComponent);
	}, []);

	if (!showXLRecirc || mobileInlineAds === undefined) { return null; }

	// 13 articles are required to render the XL recirc
	const MIN_ARTICLES_REQUIRED = 13;

	if (!data || data.length < MIN_ARTICLES_REQUIRED) { return null; }

	const count = Math.floor(data.length / MIN_ARTICLES_REQUIRED);
	const emptyArray = new Array(count).fill(0);

	return (
		emptyArray.map((_, index) => {
			const start = index * MIN_ARTICLES_REQUIRED;
			const end = (index + 1) * MIN_ARTICLES_REQUIRED;

			return (
				<XLRecircStream
					data={data.slice(start, end)}
					showTopx={showTopx}
					showRecX={showRecX}
					className={className}
					isVetted={isVetted}
					isSmallDevice={isSmallDevice}
					mobileInlineAds={mobileInlineAds}
					addProgressiveAd={addProgressiveAd}
					isDouble={!!index}
				/>
			);
		})
	);
};

XLRecirc.propTypes = {
	showTopx: PropTypes.bool,
	showRecX: PropTypes.bool,
	className: PropTypes.string,
	isVetted: PropTypes.bool,
	vettedRecircData: PropTypes.array,
};

export default XLRecirc;
