import videoTopAdStickyStateChange from '../videoManagement';
import { fireContentBarGAEvent, initializeTopStoriesClickTracker } from '../topStories';
import { updateElementClass } from '../../../shared/domUtilities';

// Fluid XL Creative Ids that match the specific Formats attached with a 30vw
const fluidXL30vwCreativeTemplateIds = {
	FluidXL_Static_Image: 11796505,
	FluidXL_Static_Image_Test: 11797790,
	FluidXL_Super: 11901605,
	FluidXL_Super_Test: 11906726,
	FluidXL_Video: 11789079,
	FluidXL_Video_Test: 11804618,
};

// Fluid XL Creative Ids that match the specific Formats attached with a 22vw
const fluidXL22vwCreativeTemplateIds = {
	FluidXL_Static_2021: 11968436,
	FluidXL_Static_2021_Test: 11961492,
	FluidXL_Super_2021: 11963866,
	FluidXL_Super_2021_Test: 11960910,
	FluidXL_Video_2021: 11968439,
	FluidXL_Video_2021_Test: 11961495,
};

// Fluid XL Creative Ids that match the specific Formats attached with the small fluid ad
const fluidMiniCreativeTemplateIds = {
	FluidXS_2021: 11898514,
};

// Fluid XL Creative Ids that match the specific Formats attached
const fluidXLCreativeTemplateIds = {
	...fluidXL30vwCreativeTemplateIds,
	...fluidXL22vwCreativeTemplateIds,
	...fluidMiniCreativeTemplateIds,
};

// List of all classes to assign based on the ad that renders
const topAdHiddenClass = 'top-ad__hide-label';
const topAnimationClass = 'top-ad-animation';
const topFluidAdClass = 'top-ad__fluid';
const topFluidXLClass = 'top-ad__fluid--xl';
const topFluidXl30vwClass = 'top-ad__fluid--thirty';
const topStaticAdClass = 'top-ad__static';
const topFluidXSClass = 'top-ad__fluid--xs';

/**
 * Sets the parent container classes
 * @param {HTMLElement || Null} topAd The top ad element on the page
 * @param {boolean} isFluid True if fluid ad is being rendered
 * @param {boolean} isFluidXL True if fluidXl is being rendered
 * @param {boolean} isFluidXL30 True if fluidXl is being rendered and it has a 30vw
 * @param {boolean} isFluidXS True if fluidXS is being rendered
 */
const toggleParentContainerClasses = (topAd = null, isFluid = false, isFluidXL = false, isFluidXL30 = false, isFluidXS = false) => {
	if (!topAd) {
		return;
	}

	updateElementClass(topAd.parentElement, topAnimationClass, 'add');
	updateElementClass(topAd.parentElement, topAdHiddenClass, 'add');
	updateElementClass(topAd.parentElement, topFluidAdClass, isFluid ? 'add' : 'remove');
	updateElementClass(topAd.parentElement, topStaticAdClass, !isFluid ? 'add' : 'remove');
	updateElementClass(topAd.parentElement, topFluidXLClass, isFluidXL ? 'add' : 'remove');
	updateElementClass(topAd.parentElement, topFluidXl30vwClass, isFluidXL30 ? 'add' : 'remove');
	updateElementClass(topAd.parentElement, topFluidXSClass, isFluidXS ? 'add' : 'remove');
};

/**
 * Sets a data-height attribute on the container element
 * @param {HTMLElement || Null} topAd The top ad element on the page
 * @param {number} adHeight The height as presented by Google Ad Manager (not the height of the unit)
 */
const addHeightAttribute = (topAd = null, adHeight = 0) => {
	if (topAd) {
		topAd.parentElement.setAttribute('data-height', adHeight);
	}
};

/**
 * Removes inline min-height if it exists
 * @param {HTMLElement || Null} topAd The top ad element on the page
 */
const removeAdditionalMinHeight = (topAd = null) => {
	if (topAd && topAd.parentElement.style.minHeight) {
		topAd.parentElement.removeAttribute('style');
	}
};

/**
 * Checks if it is time to clear the ad label interval
 * @param {HTMLElement || Null} iframe The Google Ad Manager iframe
 * @param {number} count The count of the interval
 * @returns {boolean} True if it is time to clear the interval
 */
const shouldClearInterval = (iframe = null, count = 0) => ((iframe && iframe.clientHeight) || count >= 25);

/**
 * Removes hidden ad class if the
 * @param {HTMLElement || Null} topAd The top ad element on the page
 */
const setIntervalForAdLabel = (topAd = null) => {
	let count = 0;

	if (topAd) {
		const adLabelInterval = setInterval(() => {
			const iframe = topAd.querySelector('iframe');

			// Once the iframe has loaded and has a height OR it's been 5 seconds, we will show the label
			if (shouldClearInterval(iframe, count)) {
				topAd.parentElement.classList.remove(topAdHiddenClass);
				clearInterval(adLabelInterval);
			}
			count++;
		}, 200);
	}
};

const CLASSNAME_STICKY = 'sticky-top-ad';

/**
 * Adds the sticky css class
 * @param {Object} topAdParent Parent of the top ad
 * @param {Boolean} isAdLight Tracks user subscription status
 * @param {Boolean} isStandardTemplateType Tracks if template is stardard type
 */
const makeSticky = ({
	topAdParent,
	isAdLight,
	isStandardTemplateType,
}) => {
	if (isAdLight && isStandardTemplateType) return;

	topAdParent.classList.add(CLASSNAME_STICKY);
};

/**
 * Removes the sticky css class
 * @param {Object} topAdParent Parent of the top Ad
 */
const removeSticky = (topAdParent) => {
	topAdParent.classList.remove(CLASSNAME_STICKY);
};

/**
 * Handles top ad logic when it's sticky vs. not
 * @param {Number} streamIndex Current stream index for the article in question.
 * @param {Object} adService The adService object.
 * @param {Object} adDetails Details about the ad from the ad server
 */
const handleTopAd = (streamIndex = 0, adService = null, adDetails = null) => {
	// TODO - this is done to compare top ad render timings vs STAR
	//  remove once we have confirmed the timing results
	// only fire it once - don't fire for subsequent articles in stream or ad refreshes
	if (!window.topAdEventFired) {
		window.LUX?.addData('ss-topAdLoad', performance.now());
		window.topAdEventFired = true;
	}
	const topAd = document.querySelector(`fbs-ad[ad-id="${adDetails.slot.getSlotElementId()}"]`);
	const [adWidth, adHeight] = (adDetails || {}).size || [];
	const isFluid = adWidth === 0 && adHeight === 0;
	const isFluidXL = Object.values(fluidXLCreativeTemplateIds).includes(adDetails.creativeTemplateId);
	const isFluidXL30 = Object.values(fluidXL30vwCreativeTemplateIds).includes(adDetails.creativeTemplateId);
	const isFluidXS = Object.values(fluidMiniCreativeTemplateIds).includes(adDetails.creativeTemplateId);

	if (topAd) {
		addHeightAttribute(topAd, adHeight);
		setIntervalForAdLabel(topAd);
		removeAdditionalMinHeight(topAd);
		toggleParentContainerClasses(topAd, isFluid, isFluidXL, isFluidXL30, isFluidXS);

		// We need the classes to be added to determine if the content bar was rendered or not.
		fireContentBarGAEvent(streamIndex);
		initializeTopStoriesClickTracker(streamIndex);

		const topAdParent = topAd.parentElement;

		if (!topAd.getAttribute('data-cycle-complete')) {
			const { isAdLight, isStandardTemplateType } = window.forbes['simple-site'];

			makeSticky({
				topAdParent,
				isAdLight,
				isStandardTemplateType,
			});

			topAd.setAttribute('data-cycle-complete', true);
			videoTopAdStickyStateChange(true);

			setTimeout(() => {
				videoTopAdStickyStateChange(false);

				removeSticky(topAdParent);

				if (isAdLight) {
					adService.displayBatch([`article-${streamIndex}-rec`]);
				}
			}, 5000);
		}
	}
};

export default handleTopAd;
