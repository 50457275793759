// @TODO AR-5030 refactor ticket for this file.
import '@forbes/fbs-ads/dist/main';
import { runAfterMeterVerified } from './paywallUtils';
import {
	adZone,
	retracted,
	adExperience,
	relativeVideo,
	tracking,
} from './clientConfigService';
import throttle from './throttle';
import Observable from './Observable';
import {
	insertProgressiveAd,
	progressiveAdScrollHandler,
	addTabindex,
	addRenderEventCallback,
	mobileStickyRenderCallback,
	nativeInbodyAdHandler,
} from './adInsertionServices';
import { isMobile } from './is-mobile';
import { userGaveFullConsent } from './ketchUtils';

const adConfig = new Map();

export const forceStickyAdTemplateTypes = [
	'', // empty templateType for legacy articles, where we should still show sticky ad
	'topline',
	'standard',
	'live',
];

/**
 * Sets if the ad is a blocked ad or not
 * @param {number[]} sizes The ad sizes from Google Ad Manager
 * @returns {boolean} True if serving blocked ad
 */
export const isBlockedAd = (sizes = []) => ((Array.isArray(sizes) ? sizes : [])[0] === 1 && sizes[1] === 1);

/**
 * Registers fbs-ad elements with the progressive attribute
 * to progressively load
 * @param {Array} adsToLoad Array of progressive fbs-ads
 * @TODO: Use this function to register all progressive ads once the insertAd() function
 * is completely removed
 */
export function registerProgressiveAds(adsToLoad) {
	if (!adsToLoad || !adsToLoad.length) {
		return;
	}

	adsToLoad.forEach((ad) => {
		if (['inread', 'artbottom'].indexOf(ad.getAttribute('position')) < 0) {
			insertProgressiveAd(ad);
		}
	});
}

/**
 * Grabs all progressive ads on the page and registers them
 * in the progressive queue for loading
 *
 * NOTE: If you would like more granular control over progressive ads,
 * (rather than just grabbing ALL progressive ads on the page)
 * you can use registerProgressiveAds and pass it an array of fbs-ad elements that
 * you define
 */
export function bootstrapProgressiveAds() {
	const adsToRegister = Array.from(document.querySelectorAll('fbs-ad[progressive]'));
	registerProgressiveAds(adsToRegister);
}

window.addEventListener('scroll', throttle(progressiveAdScrollHandler), { passive: true });

/**
 * Injects medianet ad into specified position.
 * @param {String} position Position name for the parent wrapper
 * @param {String} size Medianet create sizes to request
 * @param {String} crid Tag ID to request from medianet
 * @param {String} params additional attributes for the medianet ad
 */
export function insertMedianet({
	position = '', size = '300x250', crid = '311139641', getWrapperClass = (pos) => `fbs-ad--${pos}-wrapper`, params = '',
}) {
	const wrapperClassName = getWrapperClass(position);
	const wrapper = document.querySelector(`.${wrapperClassName}`);
	const currentArticleIndex = position?.split('')[position.split('').length - 1];

	if (wrapper) {
		const ad = document.createElement('div');
		if (params) {
			ad.classList.add(params);
		}
		ad.setAttribute('id', position);
		wrapper.appendChild(ad);
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.text = `
			try {
				window._mNHandle.queue.push(() => {
					window._mNDetails.loadTag('${position}', '${size}', '${crid}');
				});
				window._mNHandle.queue.push(() => {
					const adInterval = setInterval(() => {
						const mnetIframe = document.querySelector('.${wrapperClassName}').querySelector('iframe');

						if (mnetIframe) {
							mnetIframe.setAttribute('tabindex', '-1');
							mnetIframe.setAttribute('aria-hidden', 'true');
							mnetIframe.setAttribute('title', 'MediaNetIframe');
							clearInterval(adInterval);

							if((mnetIframe.style || {}).display === 'none') {
								const articleBody = document.querySelector('#article-stream-${currentArticleIndex}')
								const adLabel = articleBody.querySelector('.footer-ad-labeling');
								adLabel?.classList.add('medianet-disabled');
							}
						}
					}, 500);
				});
			} catch (error) {}
		`;

		// in next.js, the medianet.js is part of the bundle, and is likely not to be loaded yet - wait for it
		// eslint-disable-next-line no-underscore-dangle
		if (!window._mNHandle) {
			setTimeout(() => wrapper.appendChild(script));
			return;
		}

		wrapper.appendChild(script);
	}
}

/**
 * Corrects the config the Recx ad should be called with
 * @todo: This changes the zone when a new article is inserted or a new recx loads in.
 * @todo: This really needs cleaned up for readability and complexity reasons - The flow is unclear
 * @param {number} articleIndex current article location in the stream
 */
export function changeAdConfig(articleIndex = 0) {
	/* eslint-disable-next-line no-underscore-dangle */
	window.fbsads._config.ad_unit_path = adConfig.get(`${articleIndex}`);
}

/**
 * Adds a config to the ad config array whenever a new one is found
 * @param {number} streamIndex current stream index
 * @param {string} config the ad config of the article
 */
export function addNewConfig(streamIndex = 0, config = '') {
	if (config !== '') {
		adConfig.set(`${streamIndex}`, config);
	}
}

/**
 * Injects all ads of a type into the page.
 * You can optionally pass in a context.
 * @param {String} position The ad position name.
 * @param {Object} [context]  Optional DOM node to narrow scope.
 * @param {String} customAdId ID to pass to component child
 */
export function insertAd(position, context = document, customAdId) {
	const { classList } = context;
	const isArticleAdRail = classList && classList.contains('ad-rail');
	const wrapperName = isArticleAdRail ? customAdId : position;
	const wrappers = context.querySelectorAll(`.fbs-ad--${wrapperName}-wrapper`);

	for (let i = 0; i < wrappers.length; i++) {
		const ad = document.createElement('fbs-ad');

		['progressive', 'batched'].forEach((type) => {
			if (wrappers[i].classList.contains(`fbs-ad--${type}`)) {
				ad.setAttribute(type, true);
			}
		});

		// Give ads that are already in view a chance to load before displaying
		// Batched ad calls will be handled by their respective pages
		// @TODO remove this logic and possibly just use bootstrapProgressiveAds
		if (ad.getAttribute('progressive') && !ad.getAttribute('batched')) {
			setTimeout(() => {
				insertProgressiveAd(ad);
			}, 100);
		}

		ad.setAttribute('position', position);

		if (wrappers[i].dataset.customTargeting) {
			ad.setAttribute('custom-targeting', wrappers[i].dataset.customTargeting);
		}

		if (customAdId) {
			ad.setAttribute('ad-id', customAdId);
		}

		addTabindex(ad, position);
		// Add aria-hidden attribute to the wrapper - Accessibility
		if (!position === 'ntv-home' && !position === 'ntv-deskchannel') {
			if (!wrappers[i].hasAttribute('aria-hidden')) {
				wrappers[i].setAttribute('aria-hidden', 'true');
				wrappers[i].setAttribute('role', 'presentation');
			}
		}

		addRenderEventCallback(ad, position);

		wrappers[i].appendChild(ad);
	}
}

// @TODO we should just have the ads component render in the template on pug instead of using JS to insert each ad
// inside divs with certain class names.
function init() {
	// TODO: This will go away somehow
	// still want add the mobile ad logic for mobile preview
	if (isMobile || window.location.pathname.indexOf('preview/mobile') > -1) {
		// this is mainly for AMP Plus, when landing on an AMP Plus article with a relativeVideo,
		// we cannot insert a sticky ad at the bottom of the article. Super specific use case but
		// I don't know where else to put it. **shrug**.
		// Also, for adExperiences' we need to not init the ad on the first article.

		if ((!relativeVideo || forceStickyAdTemplateTypes.includes(tracking.templateType)) && adExperience !== 'none') {
			insertAd('mobile', document, 'mobile-sticky');
		}
		insertAd('mobilex');
		insertAd('mobilerec');
		insertAd('ntv-mobhome');
		insertAd('mobsearch');
		insertAd('qotd-mob');
		insertAd('nativeinbody-m');
		if (userGaveFullConsent()) {
			insertMedianet({ position: 'mobile-medianet' });
		}
	} else {
		insertAd('text');
		insertAd('ntv-home');
		insertAd('bottom');
		insertAd('qotd');
		insertAd('nativeinbody-d');
		insertMedianet({ position: 'body-medianet' });

		const topAd = document.querySelector('fbs-ad[position="top"]');
		if (topAd) {
			addRenderEventCallback(topAd, 'top');
		}
	}

	const sponLogo = document.querySelector('fbs-ad[position="spon-logo"]');
	if (sponLogo) {
		addRenderEventCallback(sponLogo, 'spon-logo');
	}

	const moreonAd = document.querySelector('fbs-ad[position="moreon"]');
	if (moreonAd) {
		addRenderEventCallback(moreonAd, 'moreon');
	}
}

const initAdsObservable = new Observable();
initAdsObservable.subscribe(init);

if (!retracted) {
	if ((adZone || '').startsWith('article')) {
		runAfterMeterVerified([initAdsObservable]);
	}
	initAdsObservable.notify();
	/**
	 * Comment out the init() and uncomment below for locally testing the hero ad.
	 */
	// setTimeout(() => {
	// 	const iframe = document.createElement('iframe');
	// 	iframe.src = 'http://fbs.forbes.com/hero-ad/';
	// 	const adWrapper = document.querySelector('.fbs-ad--top-wrapper');
	// 	adWrapper.classList.add('fbs-ad--top-wrapper--takeover');
	// 	adWrapper.append(iframe);
	// }, 1000);
}

document.addEventListener('fbs-ad-render', (event) => {
	const { detail } = event;
	const adId = detail.slot.getSlotElementId();
	if (adId === 'mobile-sticky') {
		mobileStickyRenderCallback(event);
	}
	if (adId === 'nativeinbody-d' || adId === 'nativeinbody-m') {
		nativeInbodyAdHandler(event);
	}
});

// making these accessible from outside so we can adjust it from environments like VWO
window.forbes.forceStickyAdTemplateTypes = forceStickyAdTemplateTypes;
window.forbes.insertAd = insertAd;
