import { FbsAdsService } from '@forbes/fbs-ads';

import { applyConfig, updateAdParams } from '../../../shared/adUtilities';
import Observable from '../../../shared/Observable';
import { runAfterMeterVerified } from '../../../shared/paywallUtils';
import { destroyAds } from './adLifecycleService';
import { handleAdRail } from './adRailService';
import handleTopAd from './topAdService';
import { renderBelowTheFoldAds, setArticleBottomAdValues } from './belowTheFoldAdsService';
import renderHeroAd from './heroAdService';
import { renderNTVAds, setNTVAdsValues } from './ntvAdService';
import { streamObservable, pauseAdsObservable } from '../streamService';
import { isBlockedAd } from '../../../shared/adInsertion';
import throttle from '../../../shared/throttle';

// Create access to FbsAdsService
window.fbsads = window.fbsads || new FbsAdsService();
const adService = window.fbsads;
const batchLoadingObservable = new Observable();
const loadedAds = {};
const scrollbarWidth = window.innerWidth - document.body.clientWidth;
const addAdsClassObservable = new Observable();
addAdsClassObservable.subscribe(() => {
	const currentPage = document.querySelector('.current-page');
	currentPage?.classList.add('ads-loaded');
});

let batchHasLoaded = false;
let isPremium = false;
let streamIndex = 0;

/**
 * To handle the horizontal scroll for inline topx template
*/
function handleInlineTopxAd() {
	const inlineTopxAd = document.querySelectorAll('.inline-topx fbs-ad');

	inlineTopxAd.forEach((element) => {
		element.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
	});
}

/**
 * Scroll event for batching
 */
/* eslint-disable-next-line no-use-before-define */
const handleBatchLoadingEvent = throttle(() => batchLoadingObservable.notify(), 100);

/**
 * Handles batch loading for each article
 * @param {Event} event ad slot event
 */
const handleBatchLoading = () => {
	const { isAdLight = false, isForbesFinds = false } = window.forbes['simple-site'];
	const currentSlots = [];
	adService.adSlots.forEach((key, value) => currentSlots.push(value));

	const positions = Array.from(document.querySelectorAll(`#article-container-${streamIndex} fbs-ad[batched]`))
		.filter((ad) => (isAdLight && !isForbesFinds ? ad.position === 'top' : !!ad.position))
		.map((ad) => ad.adId);

	if (batchHasLoaded || positions.filter((position) => currentSlots.indexOf(position) >= 0).length !== positions.length) {
		return;
	}

	batchHasLoaded = true;
	adService.displayBatch(positions);
	if (streamIndex) {
		/* eslint-disable-next-line no-use-before-define */
		document.removeEventListener('scroll', handleBatchLoadingEvent);
	} else {
		document.removeEventListener('fbs-ad-slots', handleBatchLoadingEvent);
	}
};

batchLoadingObservable.subscribe(handleBatchLoading);

/**
 * Gets the ad position for the render event
 * @param {string} slotElementId id for a given ad
 * @returns {string} An ad's position
 */
const getAdPosition = (slotElementId = '') => (slotElementId.replace('article', '').replace(/-/g, '').replace(/[0-9]/g, ''));

/**
 * Handles all ad labels
 * @param {HTMLElement} ad An ad element
 * @param {String} position The position of the element
 * @param {Object} detail The details for the render event
 */
const checkAdLabels = (ad, position, detail) => {
	const { size = [] } = detail;
	const isFluid = size[1] === 0;

	const validPositions = ['top', 'topx', 'rec', 'recx', 'artbottom'];
	if (validPositions.includes(position)) {
		if (!isBlockedAd(size)) {
			ad.classList.add('show-label');

			if (!isPremium && position === 'top') {
				ad.parentElement.classList.toggle('fluid', isFluid);
			}
		} else {
			ad.parentElement.classList.add('blocked-ad');
		}
	}
};

/**
 * Checks if rec and top ads are loaded because the ntv ads should be called after them
 */
const checkNTVAdRender = () => {
	const { isTopXAd } = ((window.forbes || {})['simple-site'] || {});
	if (window.forbes['simple-site'].isForbesFinds) {
		renderBelowTheFoldAds();
	} else if (loadedAds.top && (isTopXAd || loadedAds.rec) && !loadedAds.ntv && !loadedAds.below) {
		renderNTVAds();
		renderBelowTheFoldAds();
		loadedAds.below = true;
		loadedAds.ntv = true;
	}
};

/**
 * Handles position specific logic
 * @param {HTMLElement} ad An ad element
 * @param {String} position The position of the element
 * @param {Object} detail The details for the render event
 */
const handleAdByPosition = (ad, position, detail) => {
	if (isPremium) {
		if (position === 'fbsheroad' || position === 'hero') {
			renderHeroAd(detail, streamIndex);
		}
	} else {
		switch (position) {
			case 'top':
				handleTopAd(streamIndex, adService, detail);
				loadedAds.top = true;
				checkNTVAdRender();
				break;
			case 'rec':
			case 'recx':
				handleAdRail(ad, position, detail);
				loadedAds.rec = true;
				checkNTVAdRender();
				break;
			case 'sponlogo':
				ad.parentElement.parentElement.classList[isBlockedAd((detail || {}).size) ? 'add' : 'remove']('blocked-ad');
				break;
			default:
		}
	}
};

/**
 * Helper function to check if ad is valid or top ad.
 * @param {Object} ad The ad Object.
 * @param {String} position The ad position.
 */
const isValidAd = (ad = null, position = '') => (!ad.parentElement.recalledId || position === 'top');

/**
 * Handles render for all ads in an article
 * @param {Event} event ad render event
 */
const handleAdRender = (event) => {
	const { detail = {} } = event;

	// If not a real size, ignore it
	if ((detail.size || []).length !== 2) {
		return;
	}

	const ad = document.getElementById(`${detail.slot.getSlotElementId()}`);
	const position = getAdPosition(detail.slot.getSlotElementId());

	if (position === 'ntvcontentd') {
		window.forbes['simple-site'].ntvLineItem = detail.lineItemId;
		return;
	}

	if (ad) {
		checkAdLabels(ad, position, detail);
		if (isValidAd(ad, position)) {
			handleAdByPosition(ad, position, detail);
		}
	}
};

/**
 * Sets whether we are dealing with an ad rail or not
 * @param {String} adZone adzone for a given article
 * @return {Boolean} True if article is premium and therefore we do not have a ad rail
*/
const premiumAdTypes = ['masthead', 'premium'];
const setIsPremium = (adZone = '') => (premiumAdTypes.indexOf(adZone.split('/')[1]) >= 0);

/**
 * Resetting the values of the loadedAds for new articles in the stream
 */
const loadedAdsDefault = () => {
	Object.keys(loadedAds).forEach((ad) => {
		loadedAds[ad] = false;
	});
};

/**
 * Primary Ad Function. Initalizes ad process going up or down the stream.
 * @param {Object} serverData A given article server data
 */
const initAdLoad = (serverData = {}) => {
	const {
		adZone,
		asyncHistoried,
		streamIndex: newStreamIndex,
		specialSlot,
		swimlane,
		tracking = {},
	} = serverData;

	if (tracking.adBlockerDetection !== 'Detected' && newStreamIndex > streamIndex) {
		destroyAds(streamIndex);
	}

	isPremium = setIsPremium(adZone);
	streamIndex = newStreamIndex || 0;

	// If it is the first time an article is loading, we do the following
	if (asyncHistoried || streamIndex) {
		const specialSlotValue = specialSlot || swimlane || '';
		updateAdParams();
		applyConfig(specialSlotValue);
	}

	if (!asyncHistoried) {
		// If it is not the first article
		if (streamIndex) {
			batchHasLoaded = false;
			document.addEventListener('scroll', handleBatchLoadingEvent, { passive: true });
		}

		setArticleBottomAdValues(streamIndex);
		setNTVAdsValues(streamIndex, adZone);
		loadedAdsDefault();
	}
};

const pauseAdsUntilMeterVerified = () => {
	runAfterMeterVerified([streamObservable, batchLoadingObservable, addAdsClassObservable]);
	addAdsClassObservable.notify();
};

pauseAdsObservable.subscribe(pauseAdsUntilMeterVerified);
streamObservable.subscribe(initAdLoad);

document.addEventListener('fbs-ad-render', handleAdRender);
document.addEventListener('fbs-ad-render', handleInlineTopxAd);
document.addEventListener('fbs-ad-slots', handleBatchLoadingEvent);
