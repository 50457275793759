import { FbsCarousel } from '@forbes/fbs-carousel';
import multiclamp from '@forbesmedia/multiclamp';
import { fireGAEvent } from '../../shared/tracking';
import throttle from '../../shared/throttle';

let topStoriesCarousel;

/**
 * Truncates individual article titles in the promo block
 */
const truncatePromoBlockTitles = () => {
	const titles = Array.from(topStoriesCarousel.querySelectorAll('.top-stories__title'));
	titles.forEach((title) => {
		multiclamp(title, 2, false);
	});
};

/**
 * Displays previous arrorw
 */
const showLeftArrow = () => {
	const leftArrow = topStoriesCarousel.querySelector('.fbs-slider__control-left');

	if (leftArrow) {
		leftArrow.classList.remove('fbs-slider__control--hidden');
	}
};

/**
 * Checks whether in the topStories container there are VISIBLE images
 * @param {Number} streamIndex The index of the current article being viewed
 */
const areTopStoriesImagesVisible = (streamIndex) => {
	const topStoriesContainer = document.querySelector(`#article-container-${streamIndex} .top-stories`);
	if (!topStoriesContainer || window.getComputedStyle(topStoriesContainer).visibility === 'hidden') {
		return false;
	}

	const imagePlaceholdersArray = Array.from(topStoriesContainer.querySelectorAll('.top-stories-img-placeholder'));
	if (imagePlaceholdersArray.length > 0
		&& imagePlaceholdersArray.every((element) => window.getComputedStyle(element).display === 'none')) {
		return false;
	}

	return true;
};

/**
 * We want to have the data-ga-track to contain "With images" so we can track if the user clicked on an article with or without an image
 * visible (at the time of the click)
 * @param {Number} streamIndex The index of the current article being viewed
 */
const setTopStoriesClickTrackingCode = (streamIndex) => {
	const withImages = areTopStoriesImagesVisible(streamIndex) ? ' - With images' : '';

	document.querySelectorAll(
		`#article-container-${streamIndex} .top-stories__title, #article-container-${streamIndex} .top-stories-img`,
	).forEach((linkElement) => {
		const index = linkElement.getAttribute('data-index');
		const newTrackingCode = `Top Story ${index}${withImages}`;

		if (linkElement.getAttribute('data-ga-track') !== newTrackingCode) {
			linkElement.setAttribute('data-ga-track', newTrackingCode);
		}
	});
};

/**
 * Initialize the tracking codes for the top stories. In addition a resize of the window might hide/show the images in the carousel, so
 * we want to catch the resize and re-set the tracking codes as needed
 * @param {Number} streamIndex The index of the current article being viewed
 */
export const initializeTopStoriesClickTracker = (streamIndex) => {
	window.addEventListener('resize', throttle(() => {
		setTopStoriesClickTrackingCode(streamIndex);
	}, 100, this), { passive: true });

	setTopStoriesClickTrackingCode(streamIndex);
};

/**
 * Fires GA event when content bar is displayed
 * and checks whether the content bar contains images
 * @param {Number} streamIndex The index of the current article being viewed
 */
export const fireContentBarGAEvent = (streamIndex) => {
	const areImagesVisible = areTopStoriesImagesVisible(streamIndex);

	const eventLabel = areImagesVisible ? 'Top Ad Content Bar In-View - With Images' : 'Top Ad Content Bar In-View';
	const GAData = {
		eventCategory: 'Template Area Interaction',
		eventAction: 'scroll',
		eventLabel,
	};

	fireGAEvent(GAData);
};

/**
 * Sets up the promo block in the stream
 * @param {number} streamIndex The index of the article in the stream
 */
export const setUpPromoBlock = (streamIndex = 0) => {
	const { carouselConfig = null } = (window.forbes || {})['simple-site'] || {};
	topStoriesCarousel = document.querySelector(`#article-container-${streamIndex} .top-stories__carousel`);
	if (!topStoriesCarousel || !carouselConfig) {
		return;
	}

	const carouselConfigId = topStoriesCarousel.getAttribute('data-fbs-carousel-config-id');
	window.FbsCarouselConfig = {
		...window.FbsCarouselConfig,
		[`${carouselConfigId}`]: {
			...carouselConfig,
			isServerPrerender: false,
		},
	};

	const carousel = new FbsCarousel(topStoriesCarousel);
	carousel.init();
	truncatePromoBlockTitles();
	topStoriesCarousel.addEventListener('slideChangeStart', () => {
		truncatePromoBlockTitles();
		showLeftArrow();
	});
};
